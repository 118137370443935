import type { EventFilter } from '@/types/factoes'

const { api } = useApi()

export default function useFactoes() {
  const { trendingEvents, categories, history, events, event } =
    storeToRefs(useFactoesStore())

  const loading = ref({
    trending: false,
    categories: false,
    history: false,
    events: false,
    event: false,
    error: false,
  })

  const filters = computed((): EventFilter[] => {
    if (categories.value) {
      return [
        { option: 'Trending', id: '1' },
        ...categories.value!.map((category) => {
          return { option: category.name, id: category._id }
        }),
      ]
    }
    return []
  })

  async function fetchTrendingEvents() {
    try {
      loading.value.trending = true
      loading.value.error = false
      const response = await api.factoes.fetchTrendingEvents()
      if (response.success) {
        trendingEvents.value = response.data
      }
      loading.value.trending = false
    } catch (err) {
      loading.value.trending = false
      loading.value.error = true
    }
  }

  async function fetchCategories() {
    try {
      loading.value.categories = true
      loading.value.error = false
      const response = await api.factoes.fetchCategories()
      if (response.success) {
        categories.value = response.data
      }
      loading.value.categories = false
    } catch (err) {
      loading.value.categories = false
      loading.value.error = true
    }
  }

  async function fetchEventHistory() {
    try {
      loading.value.history = true
      loading.value.error = false
      const response = await api.factoes.fetchEventHistory()
      if (response.status === 'success') {
        history.value = response.data
      }
      loading.value.history = false
    } catch (err) {
      loading.value.history = false
      loading.value.error = true
    }
  }

  async function fetchEventsByCategory(categoryId: string) {
    try {
      loading.value.events = true
      loading.value.error = false
      const response = await api.factoes.fetchEventsByCategory(categoryId)
      if (response.status === 'success') {
        events.value = response.data
      }
      loading.value.events = false
    } catch (err) {
      loading.value.events = false
      loading.value.error = true
    }
  }

  async function fetchEvent(id: string) {
    try {
      loading.value.event = true
      loading.value.error = false
      const response = await api.factoes.fetchEvent(id)
      if (response.success) {
        event.value = response.data
      }
      loading.value.event = false
    } catch (err) {
      loading.value.event = false
      loading.value.error = true
    }
  }

  return {
    loading,
    fetchTrendingEvents,
    fetchCategories,
    fetchEventHistory,
    fetchEventsByCategory,
    fetchEvent,
    filters,
  }
}
