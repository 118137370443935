import type {
  TrendingEvent,
  EventCategory,
  EventHistory,
  Event,
} from '@/types/factoes'

export const useFactoesStore = defineStore('factoesStore', () => {
  const trendingEvents = ref<TrendingEvent[] | null>(null)
  const categories = ref<EventCategory[] | null>(null)
  const history = ref<EventHistory[] | null>(null)
  const events = ref<Event[] | null>(null)
  const event = ref<Event | null>(null)

  return {
    trendingEvents,
    categories,
    history,
    events,
    event,
  }
})
